import { IComboBoxOption } from '@fluentui/react'
import { useLocalAccountId } from 'src/hooks'
import { useProducts } from 'src/queries'
import { ProductVM, ProductsSelectionType } from 'src/services/graphapi'
import { useOptions } from '../../hooks'
import { CustomFilterSizeProps } from '../Props'
import { Filters } from 'src/enums'

export interface ProductOptionsProps extends CustomFilterSizeProps {
  isOwnedProducts?: boolean
  productsSelectionType?: ProductsSelectionType
  isSkuMappingReport?: boolean
}

export const useProductOptions = ({ isOwnedProducts, productsSelectionType, showTitle }: ProductOptionsProps) => {
  const localAccountId = useLocalAccountId()
  const queryResult = useProducts(isOwnedProducts ? localAccountId : undefined, productsSelectionType)

  const map = ({ id, name }: ProductVM) => ({ id, key: id || '', text: name || '' } as IComboBoxOption)
  const transform = (items: ProductVM[]) => items.map((item) => map(item))

  const options = useOptions<ProductVM>({ queryResult, transform, name: Filters.ServiceTreeProduct, showTitle })

  return options
}
