import { ProductsSelectionType, apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'
import { useQuery } from 'react-query'

const getProducts = async (ownersIds?: string, productsSelectionType?: ProductsSelectionType) => {
  const response = await apiService.products_GetProducts(ownersIds, productsSelectionType)
  return response
}

export function useProducts(ownersIds?: string, productsSelectionType?: ProductsSelectionType) {
  return useQuery([SgQueryKey.products, ownersIds, productsSelectionType], () =>
    getProducts(ownersIds, productsSelectionType),
  )
}
