import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'
import { useQuery } from 'react-query'
import { MINUTE } from 'src/constants'

const getSkuProducts = async (skuId?: string) => {
  const response = await apiService.skuProducts_GetProductsBySku(skuId)
  return response
}

export function useSkuProducts(skuId?: string, enabled = true) {
  return useQuery([SgQueryKey.skuProducts, skuId], () => getSkuProducts(skuId), {
    staleTime: 5 * MINUTE,
    enabled,
  })
}
