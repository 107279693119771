import React from 'react'
import { ProductOptionsProps, useProductOptions } from './useProductOptions'
import { useUrlAutoCompleteMultiSelect } from '../../hooks'
import { AutoCompleteMultiselect } from '../../AutoCompleteMultiselect'
import { FilterSize } from 'src/components'
import { QueryStringParam } from 'src/enums'

const SELECTED_OPTION_LIMIT = 60

export const ProductUrlAutoCompleteMultiselect: React.FunctionComponent<ProductOptionsProps> = (props) => {
  const { isOwnedProducts, productsSelectionType, filterSize = FilterSize.M, selectedIds } = props
  const options = useProductOptions({
    isOwnedProducts,
    productsSelectionType,
    selectedIds,
    showTitle: true,
  })
  const { urlValue, onChangeMultipleValues } = useUrlAutoCompleteMultiSelect({
    urlKey: QueryStringParam.ProductIds,
  })
  const selectedKeys = urlValue?.split('_') ?? []
  return (
    <AutoCompleteMultiselect
      {...options}
      selectedKey={selectedKeys}
      onChangeMultipleValues={onChangeMultipleValues}
      filterSize={filterSize}
      selectedOptionLimit={options.options.length >= SELECTED_OPTION_LIMIT ? SELECTED_OPTION_LIMIT : undefined}
    />
  )
}
