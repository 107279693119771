import { lazy } from 'react'
import { ILandingPage } from '../Models'
import { CONFIDENTIAL_FLAG, SKU_MAPPING_REPORT_V2 } from 'src/constants'

const SkuMappingReportPageV2 = lazy(() => import('../../../pages/SkuMappingReport/SkuMappingReportV2'))

export const SkuMappingReportRouteV2 = {
  navigationTitle: SKU_MAPPING_REPORT_V2,
  icon: 'ReportDocument',
  description: [`(${CONFIDENTIAL_FLAG})`],
  externalLink: 'https://eng.ms/docs/experiences-devices/ed-inventory-management/commerceskumap/overview',
  externalLinkText: 'SKU Mapping Documentation',
  element: <SkuMappingReportPageV2 />,
} as ILandingPage
