import React from 'react'
import { useUrlAutoCompleteMultiSelect } from '../../hooks'
import { AutoCompleteMultiselect } from '../../AutoCompleteMultiselect'
import { FilterSize } from 'src/components'
import { QueryStringParam } from 'src/enums'
import { SkuProductOptionsProps, useSkuProductOptions } from './useSkuProductsOptions'
import { useSearchParamValue } from 'src/hooks'

const SELECTED_OPTION_LIMIT = 60

export const SkuProductUrlAutoCompleteMultiselect: React.FunctionComponent<SkuProductOptionsProps> = (props) => {
  const { filterSize = FilterSize.M } = props
  const [skuId] = useSearchParamValue(QueryStringParam.SkuIds)
  const options = useSkuProductOptions({
    skuId,
    showTitle: true,
  })
  const { urlValue, onChangeMultipleValues } = useUrlAutoCompleteMultiSelect({
    urlKey: QueryStringParam.ProductIds,
  })
  const selectedKeys = urlValue?.split('_') ?? []
  return (
    <AutoCompleteMultiselect
      {...options}
      selectedKey={selectedKeys}
      onChangeMultipleValues={onChangeMultipleValues}
      filterSize={filterSize}
      selectedOptionLimit={options.options.length >= SELECTED_OPTION_LIMIT ? SELECTED_OPTION_LIMIT : undefined}
    />
  )
}
