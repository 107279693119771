import { IComboBoxOption } from '@fluentui/react'
import { useSkuProducts } from 'src/queries'
import { ProductSkuVM } from 'src/services/graphapi'
import { useOptions } from '../../hooks'
import { CustomFilterSizeProps } from '../Props'
import { Filters } from 'src/enums'

export interface SkuProductOptionsProps extends CustomFilterSizeProps {
  skuId?: string
}

export const useSkuProductOptions = ({ skuId, showTitle }: SkuProductOptionsProps) => {
  const queryResult = useSkuProducts(skuId)

  const map = ({ id, name }: ProductSkuVM) => ({ id, key: id || '', text: name || '' } as IComboBoxOption)
  const transform = (items: ProductSkuVM[]) => items.map((item) => map(item))

  const options = useOptions<ProductSkuVM>({ queryResult, transform, name: Filters.ServiceTreeProduct, showTitle })

  return options
}
