export enum LandingPages {
  Welcome = '',
  RTI = 'rti',
  DataFlows = 'data-flow',
  Resources = 'resources',
  Models = 'models',
  EUDBAlerts = 'boundaryAlerts',
  AIML = 'aiml-eu-dashboard',
  CloudParityReport = 'cloud-parity-report',
  SkuMappingReport = 'sku-mapping-report',
  SkuMappingReportV2 = 'sku-mapping-report-v2',
  Help = 'help',
  SGIOverview = 'insights',
  SGIAppView = 'insights-app-view',
  CardinalityView = 'cardinality-view',
  ServiceModels = 'service-models',
}
