import { IComboBoxOption } from '@fluentui/react'
import { useSearchParamValue } from 'src/hooks'
import { useProducts, useServicesByProducts } from 'src/queries'
import { ProductsSelectionType, ServiceVM } from 'src/services/graphapi'
import { useOptions } from '../../hooks'
import { Filters, QueryStringParam } from 'src/enums'

const useUsedProductIds = () => {
  const usedProducts = useProducts(undefined, ProductsSelectionType.FeatureConnectedOnly)
  return usedProducts.data?.map((item) => item.id).join('_')
}

export const useServicesByProductOptions = () => {
  const [productIds] = useSearchParamValue(QueryStringParam.ProductIds)
  const usedProductIds = useUsedProductIds()
  const queryResult = useServicesByProducts(productIds ? productIds : usedProductIds)
  const map = (item: ServiceVM) => ({ key: item.id || '', text: item.name || '' } as IComboBoxOption)
  const transform = (items: ServiceVM[]) => items.map((item) => map(item))

  const options = useOptions<ServiceVM>({ queryResult, transform, name: Filters.Service })

  return options
}
